﻿import IMask from 'imask';

import { BlazorLocalStorage } from './components/blazor-local-storage';
import { BlazorSessionStorage } from './components/blazor-session-storage';
import { ConnectivityHandler } from './components/connectivity-handler';
import { InstallationHandler } from './components/installation-handler';
import { NotificationsHandler } from './components/notifications-handler';
import { OIDC } from './components/oidc';

window['BlazorLocalStorage'] = BlazorLocalStorage;
window['BlazorSessionStorage'] = BlazorSessionStorage;
window['NotificationsHandler'] = NotificationsHandler;
window['OIDC'] = OIDC;


// Function called from WebAssembly
window['initializeDotNetObject'] = (dotNetObject: DotNet.DotNetObject) => {
    window['ConnectivityHandler'] = new ConnectivityHandler(dotNetObject);
    window['InstallationHandler'] = new InstallationHandler(dotNetObject);
    console.log("DOM dotNetObject initialized.");
}

window['setPageTitle'] = (title: string) => {
    document.title = title;
}

window['getElementHeight'] = (element: Element): number => {
    if (!element)
        return null;

    const offsetHeight: number | null = element['offsetHeight'];
    if (!offsetHeight || offsetHeight > (window.innerHeight - element.getBoundingClientRect().top)) {
        return Math.floor(window.innerHeight - element.getBoundingClientRect().top);
    }

    return Math.floor(element['offsetHeight']) ?? null;
}

window['getElementDistanceToBottom'] = (element: Element): number => {
    if (!element)
        return null;

    return Math.floor(window.innerHeight - element.getBoundingClientRect().y);
}

window['tableObserverInit'] = (tableId: string) => {
    if (!tableId)
        return null;

    const tableBodyElement: Element = document.getElementById(tableId)?.querySelector("tbody");
    if (!tableBodyElement)
        return null;

    const observer: MutationObserver = new MutationObserver(window['maskInputs']);
    observer.observe(tableBodyElement, { childList: true });
};

window['maskInputs'] = () => {
    const inputMaskParameterName = "data-inputmask";
    const elements: NodeListOf<Element> = document.querySelectorAll("[" + inputMaskParameterName + "]");

    elements.forEach((element: HTMLElement) => {
        if (element instanceof HTMLInputElement) {
            new IMask.InputMask(<HTMLInputElement>element, { mask: element.getAttribute(inputMaskParameterName) });
            return;
        }
        const masked = IMask.createMask({ mask: element.getAttribute(inputMaskParameterName) });
        element.innerHTML = masked.resolve(element.innerHTML);
    });
};

window['saveAsFile'] = (filename: string, bytesBase64: string) => {
    const link: HTMLAnchorElement = document.createElement('a');
    link.download = filename;
    link.href = URL.createObjectURL(new Blob([bytesBase64]));
    link.type = "text/plain";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

